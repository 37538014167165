<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
        md="12"
      >
            <v-card>
                <v-card-title class="red--text">
                   RESULTS ACCESS
                </v-card-title>
                <v-card-subtitle>
                    Parents, Teachers, Students
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <!-- Records -->
                        <v-col cols="12">
                            <v-alert type="info" text>Login with your <strong>PIN &amp; KEY</strong> to gain access. You should never disclose your PIN/KEY to anyone</v-alert>
                            <v-form ref="form">
                                <v-container>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        md="6"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.key"
                                                label="KEY"
                                                :rules="[v => !!v || 'Enter your KEY']"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="6"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.pin"
                                                label="PIN"
                                                :rules="[v => !!v || 'Enter your PIN']"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="6"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.fullname"
                                                label="FIRST OR LAST NAME (not both)"
                                                :rules="[v => !!v || 'Enter your first or last name']"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="6"
                                        >
                                            <v-btn color="success"
                                            @click="postRecord"
                                            >
                                                GO &raquo;
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeaderVue from '../../components/Header.vue'
import axios from 'axios';
  export default {
    name: 'MultiAccessLogin',
    components: {
        HeaderVue,
    },
    mounted() {//
    },
    data: () => ({
        form: {},
    }),
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    methods: {
        postRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/multiaccess/signin',this.form,
            {})
            .then((response) => {
                sessionStorage.accessToken =  response.data.data.access_token
                sessionStorage.utpc =  response.data.data.user_type
                localStorage.school =  JSON.stringify(response.data.data.school)
                this.$store.commit("isTokenAccess", true);
                this.$store.commit("isLoggedIn", false);
                sessionStorage.removeItem('authToken')
                if (response.data.data.user_type == 'Teacher') {
                    this.$router.push('scores')
                } 
                else{
                    this.$router.push('sheets')
                }            
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
    },
  }
</script>

<style>

</style>
